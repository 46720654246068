module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7a8fe9d0f7fcf98ee86ba0f95bd5a30c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase-messaging/gatsby-browser.js'),
      options: {"plugins":[],"config":{"apiKey":"AIzaSyCcNCe6RHTDNKotnrm4FMz6TFwJvjgfeVQ","authDomain":"test-51594.firebaseapp.com","databaseURL":"https://test-51594.firebaseio.com","projectId":"test-51594","storageBucket":"test-51594.appspot.com","messagingSenderId":"353050915505","appId":"1:353050915505:web:37e44301a7ea6ee5","measurementId":""},"disableDevelopment":false,"removeFirebaseServiceWorker":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"253268389712575"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
