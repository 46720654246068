// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-congratulations-js": () => import("./../../../src/pages/congratulations.js" /* webpackChunkName: "component---src-pages-congratulations-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/ContactUs.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-create-student-account-js": () => import("./../../../src/pages/create-student-account.js" /* webpackChunkName: "component---src-pages-create-student-account-js" */),
  "component---src-pages-create-teacher-account-js": () => import("./../../../src/pages/create-teacher-account.js" /* webpackChunkName: "component---src-pages-create-teacher-account-js" */),
  "component---src-pages-dev-js": () => import("./../../../src/pages/dev.js" /* webpackChunkName: "component---src-pages-dev-js" */),
  "component---src-pages-edit-task-js": () => import("./../../../src/pages/EditTask.js" /* webpackChunkName: "component---src-pages-edit-task-js" */),
  "component---src-pages-feedback-form-js": () => import("./../../../src/pages/FeedbackForm.js" /* webpackChunkName: "component---src-pages-feedback-form-js" */),
  "component---src-pages-file-hours-inquiry-js": () => import("./../../../src/pages/FileHoursInquiry.js" /* webpackChunkName: "component---src-pages-file-hours-inquiry-js" */),
  "component---src-pages-handle-verification-js": () => import("./../../../src/pages/HandleVerification.js" /* webpackChunkName: "component---src-pages-handle-verification-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/Help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../../../src/pages/Join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-join-slack-js": () => import("./../../../src/pages/JoinSlack.js" /* webpackChunkName: "component---src-pages-join-slack-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/Login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-meet-the-team-js": () => import("./../../../src/pages/MeetTheTeam.js" /* webpackChunkName: "component---src-pages-meet-the-team-js" */),
  "component---src-pages-more-about-remote-ta-js": () => import("./../../../src/pages/MoreAboutRemoteTA.js" /* webpackChunkName: "component---src-pages-more-about-remote-ta-js" */),
  "component---src-pages-post-task-js": () => import("./../../../src/pages/PostTask.js" /* webpackChunkName: "component---src-pages-post-task-js" */),
  "component---src-pages-profile-student-js": () => import("./../../../src/pages/ProfileStudent.js" /* webpackChunkName: "component---src-pages-profile-student-js" */),
  "component---src-pages-profile-teacher-js": () => import("./../../../src/pages/ProfileTeacher.js" /* webpackChunkName: "component---src-pages-profile-teacher-js" */),
  "component---src-pages-send-reset-email-js": () => import("./../../../src/pages/SendResetEmail.js" /* webpackChunkName: "component---src-pages-send-reset-email-js" */),
  "component---src-pages-student-dashboard-js": () => import("./../../../src/pages/student-dashboard.js" /* webpackChunkName: "component---src-pages-student-dashboard-js" */),
  "component---src-pages-student-login-js": () => import("./../../../src/pages/student-login.js" /* webpackChunkName: "component---src-pages-student-login-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/Support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-teacher-congrats-js": () => import("./../../../src/pages/teacherCongrats.js" /* webpackChunkName: "component---src-pages-teacher-congrats-js" */),
  "component---src-pages-teacher-dashboard-js": () => import("./../../../src/pages/teacher-dashboard.js" /* webpackChunkName: "component---src-pages-teacher-dashboard-js" */),
  "component---src-pages-teacher-login-js": () => import("./../../../src/pages/teacher-login.js" /* webpackChunkName: "component---src-pages-teacher-login-js" */),
  "component---src-pages-unsubscribe-from-updates-js": () => import("./../../../src/pages/UnsubscribeFromUpdates.js" /* webpackChunkName: "component---src-pages-unsubscribe-from-updates-js" */),
  "component---src-pages-verify-email-js": () => import("./../../../src/pages/VerifyEmail.js" /* webpackChunkName: "component---src-pages-verify-email-js" */),
  "component---src-pages-view-tasks-js": () => import("./../../../src/pages/ViewTasks.js" /* webpackChunkName: "component---src-pages-view-tasks-js" */)
}

